// Contact Us with Form
.contact-form-wrap {
  border: 0;
  padding: 0;
  margin: 0;
}
.contact-list {
  @include font-size(13);
  @include line-height(20);
  text-transform: uppercase;
  font-weight: 700;
  list-style: none;
  padding: 0;
  li {
    margin: 20px 0;
  }
  a {
    color: $white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.contact-block {
  background: url(../img/contact-banner.jpg) 0 0 no-repeat;
  background-size: cover;
  color: $white;
  @include breakpoint(m) {
    margin-bottom: 0;
  }
}
.contact-block-content {
  margin-bottom: 60px;
  @include breakpoint(l) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.contact-block-form {
  @include breakpoint(l) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.contact-submit {
  margin-top: 20px;
  padding: .5em 2.5em;
  font-weight: 400;
}

// Contact List Icons
.contact-icon {
  padding-left: 40px;
  display: block;
  padding-top: 5px;
  position: relative;
  &:before {
    content: "";
    background-repeat: no-repeat;
    border: 0;
    width: 30px;
    height: 30px;
    border: 2px solid $pink;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -15px;
  }
}
.contact-icon-location:before {
    background-image: url(../img/mapmarker.svg);
    background-position: 50% 50%;
}
.contact-icon-phone:before {
    background-image: url(../img/phone.svg);
    background-position: 50% 50%;
}
.contact-icon-email:before {
    background-image: url(../img/email.svg);
    background-position: 50% 50%;
}

// Contact Us Animations
.contact-block.active {
  .contact-block-content {
    @include animation(1s);
    animation-delay: 0.4s;
    @extend .fade-in-right;
  }
  .contact-block-form {
    @include animation(1s);
    animation-delay: 0.4s;
    @extend .fade-in-left; 
  }
}